import React, { useEffect, useState } from "react";
import { useTheme } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import MegaMenu from "./MegaMenu";
import IconExpandLess from "@material-ui/icons/ExpandLess";
import IconExpandMore from "@material-ui/icons/ExpandMore";
import clsx from "clsx";
import Item from "./Item";
import { Box, ClickAwayListener, ListItem, SvgIcon, useMediaQuery } from "@material-ui/core";
import { usePageContext } from "../Context/PageContextProvider";
import { useStyles } from "./NavigationItems.style";
var SupportedKeyCodeEvents;
(function (SupportedKeyCodeEvents) {
    SupportedKeyCodeEvents["Enter"] = "Enter";
    SupportedKeyCodeEvents["Escape"] = "Escape";
    SupportedKeyCodeEvents["Space"] = "Space";
    SupportedKeyCodeEvents["Tab"] = "Tab";
})(SupportedKeyCodeEvents || (SupportedKeyCodeEvents = {}));
const NavigationItems = (props) => {
    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const { isServerSideRendering } = usePageContext();
    const theme = useTheme();
    const isDesktop = isServerSideRendering && typeof prefilledDataSSR !== "undefined"
        ? JSON.parse(prefilledDataSSR.customerDevice)?.isMobile.toString() === "false"
        : useMediaQuery(theme.breakpoints.up("md"), { noSsr: true });
    const { selectedPage, setSelectedPage, selectedRootMenuItem, setSelectedRootMenuItem, isUSPBanner, hideMOBanner } = usePageContext();
    const isActive = selectedRootMenuItem === props.Name;
    if (open && isDesktop) {
        if (selectedPage !== props.Name) {
            setOpen(false);
        }
    }
    useEffect(() => {
        if (open && isDesktop && selectedPage !== props.Name) {
            handleClickAway();
        }
    }, [open, selectedPage, props.Name]);
    const handleClick = () => {
        setOpen(!open);
        setSelectedRootMenuItem(props.Name);
        setSelectedPage(props.Name);
    };
    const handleOpenClick = () => {
        setOpen(!open);
    };
    const handleMegaMenuClick = () => {
        setOpen(!open);
        setSelectedPage(props.Name);
    };
    const handleClickAway = () => {
        setOpen(false);
    };
    const handleKeyDown = (event, rootMenuItem) => {
        const pressedButtonCode = event.nativeEvent.code;
        if (pressedButtonCode === SupportedKeyCodeEvents.Escape) {
            handleClickAway();
        }
        else if (rootMenuItem && (pressedButtonCode === SupportedKeyCodeEvents.Enter || pressedButtonCode === SupportedKeyCodeEvents.Space)) {
            handleMegaMenuClick();
            event.preventDefault();
            event.stopPropagation();
        }
    };
    const MenuItemRoot = isDesktop ? (React.createElement(ListItem, { className: classes.menuItem, onClick: handleMegaMenuClick, disableGutters: true },
        React.createElement(ListItemText, { tabIndex: 0, disableTypography: true, className: isActive ? classes.activeItem : classes.text, primary: props.Name, onKeyDown: (event) => handleKeyDown(event, true) }))) : (React.createElement(Box, { className: classes.itemWrapper },
        React.createElement(Item, { className: classes.mobMenuItem, link: props.Link },
            React.createElement(ListItemText, { tabIndex: 0, disableTypography: true, primary: props.Name, onClick: () => {
                    props.handleDrawer();
                    handleClick();
                }, onKeyDown: handleKeyDown })),
        props.HasChildren && Boolean(props.Children?.length) &&
            !open &&
            ((theme.icons?.expandIconNavigation && (React.createElement(SvgIcon, { color: 'primary', onClick: handleOpenClick, className: classes.openIcon }, theme.icons?.expandIconNavigation))) ?? React.createElement(IconExpandMore, { onClick: handleOpenClick, className: classes.openIcon })),
        props.HasChildren && Boolean(props.Children?.length) &&
            open &&
            ((theme.icons?.expandIconNavigation && (React.createElement(SvgIcon, { color: 'primary', className: clsx(classes.expandedIcon, classes.openIcon), onClick: handleOpenClick }, theme.icons?.expandIconNavigation))) ?? React.createElement(IconExpandLess, { onClick: handleOpenClick, className: classes.openIcon }))));
    const MenuItemChildren = isDesktop
        ? open && (React.createElement(React.Fragment, null,
            React.createElement(Box, { className: isUSPBanner && !hideMOBanner ? classes.collapseBackdropWithUSPBanner : classes.collapseBackdrop }),
            React.createElement(Collapse, { in: open, timeout: 'auto', unmountOnExit: true, className: isUSPBanner && !hideMOBanner ? classes.childrenWithUSPBanner : classes.children, classes: { wrapper: classes.wrapper }, component: 'div' },
                React.createElement(ClickAwayListener, { onClickAway: handleClickAway },
                    React.createElement("div", { key: 'menu' },
                        React.createElement(MegaMenu, { menu: props, handleOpen: handleClick, handleKeyPress: handleKeyDown }))))))
        : props.HasChildren && (React.createElement(Collapse, { in: open, timeout: 'auto', unmountOnExit: true, className: classes.children, component: 'div' },
            React.createElement(React.Fragment, null,
                React.createElement(List, { component: 'div', className: classes.menuChildren, disablePadding: true }, props.Children &&
                    props.Children.map((item, index) => React.createElement(NavigationItems, Object.assign({}, item, { key: index, handleDrawer: props.handleDrawer })))))));
    return (React.createElement(React.Fragment, null,
        MenuItemRoot,
        MenuItemChildren));
};
export default NavigationItems;
