import { makeStyles } from "@material-ui/core/styles";
export const useStyles = makeStyles((theme) => ({
    menuItem: {
        padding: 0,
        "&.active": {
            color: theme.colorCard?.primary || theme.palette?.primary?.main,
            textDecoration: "underline",
        },
        "& :hover": {
            textDecoration: "underline",
            cursor: "pointer",
            ...theme.customStyling?.NavigationItems?.item?.hover,
        },
        backgroundColor: theme.colorCard?.background2 || theme.navigation.backgroundColor,
        borderBottom: `1px solid ${theme.colorCard?.dividerLine || theme.navigation?.divider}`,
        [theme.breakpoints.up("md")]: {
            backgroundColor: "rgba(255,0,0,0)",
            order: 1,
            width: "unset",
            border: "none",
        },
        ...theme.customStyling?.NavigationItems?.item,
    },
    mobMenuItem: {
        [theme.breakpoints.down("md")]: {
            borderBottom: `1px solid ${theme.colorCard?.dividerLine || theme.navigation?.divider}`,
            padding: 16,
        },
        color: theme.colorCard?.inputText || theme.palette?.text?.primary,
        fontFamily: theme.typography.fontFamily,
        "&.active": {
            color: theme.colorCard?.primary || theme.palette?.primary?.main,
            fontWeight: 600,
        },
        ...theme.customStyling?.NavigationItems?.mobMenuItem,
    },
    text: {
        "&.active": {
            color: theme.colorCard?.primary || theme.palette?.primary?.main,
        },
        fontFamily: theme.typography.fontFamily,
        color: theme.colorCard?.inputText || theme.palette?.text?.primary,
        ...theme.customStyling?.NavigationItems?.text,
    },
    activeItem: {
        color: theme.colorCard?.primary || theme.palette?.primary?.main,
        fontFamily: theme.typography.fontFamily,
        fontWeight: 600,
        ...theme.customStyling?.NavigationItems?.activeItem,
    },
    childrenWithUSPBanner: {
        [theme.breakpoints.up("md")]: {
            backgroundColor: "#00000000",
            order: 3,
            position: "fixed",
            left: 0,
            right: 0,
            top: 194,
            overflow: "auto",
            maxHeight: "calc(100vh - 194px)",
        },
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 0,
            background: "transparent",
        },
        scrollbarWidth: "none",
    },
    children: {
        [theme.breakpoints.up("md")]: {
            backgroundColor: "#00000000",
            order: 3,
            position: "fixed",
            left: 0,
            right: 0,
            top: 146,
            overflow: "auto",
            maxHeight: "calc(100vh - 146px)",
        },
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 0,
            background: "transparent",
        },
        scrollbarWidth: "none",
    },
    menuChildren: {
        paddingLeft: 20,
        [theme.breakpoints.up("md")]: {
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            paddingLeft: 0,
        },
    },
    openIcon: {
        color: theme.colorCard?.primary || theme.palette?.primary?.main,
        padding: 10,
        marginRight: 14,
    },
    collapseBackdrop: {
        [theme.breakpoints.up("md")]: {
            position: "fixed",
            backgroundColor: "rgba(51,51,51, 0.75)",
            width: "100vw",
            height: "100vh",
            top: 146,
            left: 0,
        },
    },
    collapseBackdropWithUSPBanner: {
        [theme.breakpoints.up("md")]: {
            position: "fixed",
            backgroundColor: "rgba(51,51,51, 0.75)",
            width: "100vw",
            height: "100vh",
            top: 194,
            left: 0,
        },
    },
    wrapper: {
        backgroundColor: theme.colorCard?.background2 ||
            theme.navigation.mobileCollapseBackgroundColor,
        maxWidth: "calc(1120px - 48px)",
        margin: "0 auto",
        border: `1px solid ${theme.colorCard?.dividerLine || theme.navigation?.divider}`,
        overflowY: "scroll",
        "&::-webkit-scrollbar": {
            width: 0,
            background: "transparent",
        },
        scrollbarWidth: "none",
        paddingBottom: 24,
        ...theme.customStyling?.NavigationItems?.wrapper,
    },
    itemWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            borderBottom: `1px solid ${theme.navigation?.divider || theme.colorCard?.background2}`,
            "&:hover": {
                backgroundColor: theme.colorCard?.tableOutlines,
            },
        },
        ...theme.customStyling?.NavigationItems?.itemWrapper,
    },
    expandedIcon: {
        transform: `rotate(${theme.icons?.expandIconRotationNavigation}deg)`,
    },
}));
